// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LatestListing from "./LatestListing";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate, useParams } from "react-router-dom";
import SellerHeader from "../Layout/SellerHeader";
import TruncateText from "../TruncateText/TruncateText";
import HomeSlider from "../Home/HomeSlider";
import { Button, Col, Input, Modal, Row } from "antd";
import { toast } from "react-toastify";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import RecentProduct from "./RecentProduct";
import Seo from "../Seo/Seo";

function VendorStoreCom({}) {
  const router = useNavigate();
  // const { id } = router.query;
  const { id } = useParams();
  const dispatch = useDispatch();
  const get_like_list = useSelector((state) =>
    state.allapi.get_like_list ? state.allapi.get_like_list : {}
  );
  const vendorDetails = useSelector((state) =>
    state.allapi.get_seller_details ? state.allapi.get_seller_details : {}
  );

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valueuser, setvalueuser] = useState({});
  const [postList, setpostList] = useState({});
  console.log(postList);
  console.log(postList?.listing);
  const prolist = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res2 = await fetch(
      URL.API_BASE_URL + `${URL.getvendorprofileproduct}`,
      requestOptions
    );

    const events2 = await res2.json();

    const postData2 = events2?.data;
    console.log(postData2);
    setpostList(postData2);
  };

  useEffect(() => {
    prolist();
    dispatch(allapiAction.getsliderList({ page: "home" }));
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
      const userDeatail = localStorage.getItem("access_user");

      if (userDeatail) {
        const obj = JSON.parse(userDeatail);
        setvalueuser(obj);
      }
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);
  useEffect(() => {
    if (id) {
      if (value || valuevendor) {
        dispatch(allapiAction.userlikeget({ user_id: id }));
      }

      return () => {};
    }
  }, [id, valuevendor, value]);

  const hendletofollow = async () => {
    await dispatch(allapiAction.userfollownow({ user_id: id }));
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  };

  const hendletoLike = async () => {
    await dispatch(allapiAction.userlikenow({ user_id: id }));
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  };

  const [windowWidth, setWindowWidth] = useState(600);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [enquiryData, setenquiryData] = useState({});

  const onChangeData = (e) => {
    setenquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };
  const hendletoSubmitEnquiry = async () => {
    if (!enquiryData?.name) {
      toast?.error("please enter your name");
      return;
    }
    if (!enquiryData?.number) {
      toast?.error("please enter your number");
      return;
    }
    if (!enquiryData?.address) {
      toast?.error("please enter your address");
      return;
    }

    const response = await dispatch(
      allapiAction.emailenquirysubmit({
        sellerId: vendorDetails?._id,
        sellerName: vendorDetails?.displayName,
        // productId: get_product_details?._id,
        // productName: get_product_details?.title,
        userName: enquiryData?.name,
        address: enquiryData?.address,
        // country,
        // countryCode,
        whatsappNo: enquiryData?.number,
        customerEmail: enquiryData?.email,
        message: enquiryData?.message,
        subject: "Inquiry From shop",
        // sellerEmail,
        // query,
      })
    );

    console.log(response);
    if (response?.success) {
      setstatusDataBoxReturn(false);
      setenquiryData({
        name: "",
        email: "",
        number: "",
        address: "",
        message: "",
      });
    }
  };

  const [shareshow, setstshareshow] = useState(false);
  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      // Outputs: '/blog/react-get-current-url/'

      pageUrl = window.location.href;
    }
  }

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");

    setstshareshow(false);
  }

  console.log(vendorDetails);
  console.log(vendorDetails?.displayName);
  return (
    <div>
      <Seo
        settitle={vendorDetails?.displayName}
        titleSeo={vendorDetails?.displayName}
        seoKeyword={vendorDetails?.displayName}
        seoconcal={"https://devimart.com/vendor-store/" + vendorDetails?._id}
        seo_descriptions={vendorDetails?.displayName}
        title={vendorDetails?.displayName}
      />
      <SellerHeader />
      <div className="hometest">
        <HomeSlider />
      </div>

      <Modal
        title="Enquiry Now !!"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendletoSubmitEnquiry(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Store Name</Col>
            <Col span={24}>
              <Input
                name="storename"
                placeholder="enter store name..."
                value={vendorDetails?.displayName}
                // onChange={(e) => {
                //   onChangeData(e);
                // }}
              />
            </Col>
            <Col span={24}>Name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter name..."
                value={enquiryData?.name}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Number</Col>
            <Col span={24}>
              <Input
                name="number"
                placeholder="enter number..."
                value={enquiryData?.number}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Email</Col>
            <Col span={24}>
              <Input
                name="email"
                placeholder="enter email..."
                value={enquiryData?.email}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address..."
                value={enquiryData?.address}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>message</Col>
            <Col span={24}>
              <Input
                name="message"
                placeholder="enter message..."
                value={enquiryData?.message}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Share"
        centered
        open={shareshow}
        onOk={() => setstshareshow(false)}
        onCancel={() => setstshareshow(false)}
        width={500}
        footer={null}
      >
        <div>
          <div className="row unishareIcon">
            <div className="col-2 mt-3">
              <FacebookShareButton
                url={pageUrl}
                // quote={blog?.heading}
              >
                <FacebookIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </FacebookShareButton>
            </div>
            <div className="col-2 mt-3">
              <TwitterShareButton url={pageUrl}>
                <TwitterIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TwitterShareButton>
            </div>
            <div className="col-2 mt-3">
              <WhatsappShareButton url={pageUrl}>
                <WhatsappIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </WhatsappShareButton>
            </div>
            <div className="col-2 mt-3">
              <LinkedinShareButton url={pageUrl}>
                <LinkedinIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </LinkedinShareButton>
            </div>
            <div className="col-2 mt-3">
              <EmailShareButton url={pageUrl}>
                <EmailIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </EmailShareButton>
            </div>
            <div className="col-2 mt-3">
              <MailruShareButton url={pageUrl}>
                <MailruIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </MailruShareButton>
            </div>
            <div className="col-2 mt-3">
              <OKShareButton url={pageUrl}>
                <OKIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </OKShareButton>
            </div>
            <div className="col-2 mt-3">
              <PinterestShareButton url={pageUrl}>
                <PinterestIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PinterestShareButton>
            </div>
            <div className="col-2 mt-3">
              <PocketShareButton url={pageUrl}>
                <PocketIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PocketShareButton>
            </div>
            <div className="col-2 mt-3">
              <RedditShareButton url={pageUrl}>
                <RedditIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </RedditShareButton>
            </div>
            <div className="col-2 mt-3">
              <TelegramShareButton url={pageUrl}>
                <TelegramIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TelegramShareButton>
            </div>
            <div className="col-2 mt-3">
              <TumblrShareButton url={pageUrl}>
                <TumblrIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TumblrShareButton>
            </div>
          </div>

          <div className="shareLinkInModel">
            {" "}
            <p>
              <b>{pageUrl}</b>
            </p>
          </div>
          <div className="shareLinkInModel">
            <Button
              variant="primary"
              onClick={() => {
                copy();
                // onCloseModal();
              }}
            >
              {!copied ? "Copy link" : "Copied!"}
            </Button>
          </div>
        </div>
      </Modal>
      <div className="marquee">
        <marquee behavior="scroll" direction="left" scrollamount="05">
          {vendorDetails?.displayName
            ? vendorDetails?.displayName
            : vendorDetails?.fullName}
        </marquee>
      </div>
      <div className="container-fluid vdstore">
        <div class="row pt-10 pb-10 mb-20 bg-light">
          <div class="col-md-4 col-12">
            <img
              src={
                vendorDetails?.sellerProfile
                  ? URL.API_BASE_URL + vendorDetails?.sellerProfile
                  : "/img/message/sudarshanchakra.gif"
              }
              alt=""
              class="vendershopimage"
            />
          </div>
          <div class="col-md-8 col-12">
            <h4>
              {" "}
              {vendorDetails?.displayName
                ? vendorDetails?.displayName
                : vendorDetails?.fullName}
            </h4>
            <h6>
              {vendorDetails?.city}, {vendorDetails?.state},{" "}
              {vendorDetails?.country},{vendorDetails?.zip}
            </h6>
            {/* <h5>1234567890</h5> */}
            <div className="ps-block__footer pb-3">
              {valuevendor || value ? (
                valueuser?._id !== id ? (
                  get_like_list?.userFollow?.status == "active" ? (
                    <button
                      className="ps-btn2  "
                      onClick={() => {
                        hendletofollow();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.follower} Following
                    </button>
                  ) : (
                    <button
                      className="ps-btn2  "
                      onClick={() => {
                        hendletofollow();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.follower} Follow
                    </button>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {valuevendor || value ? (
                valueuser?._id !== id ? (
                  get_like_list?.userlike?.status == "active" ? (
                    <button
                      className="ps-btn2 ml-10 "
                      onClick={() => {
                        hendletoLike();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.like} <i className="fa fa-thumbs-up"></i>
                    </button>
                  ) : (
                    <button
                      className="ps-btn2  ml-10"
                      onClick={() => {
                        hendletoLike();
                      }}
                      // href={"#"}
                    >
                      {vendorDetails?.like} <i class="far fa-thumbs-up"></i>
                    </button>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="ps-product__shopping">
              <button
                className="ps-btn5"
                // href="#"
                onClick={() => {
                  setstatusDataBoxReturn(true);
                }}
              >
                Contect Seller
              </button>
              <button
                className="ps-btn3 ml-5"
                // href="#"
                onClick={() => {
                  setstshareshow(true);
                }}
              >
                Share
              </button>
              <button
                className="ps-btn4 ml-5"
                // href="#"
                onClick={() => {
                  setstatusDataBoxReturn(true);
                }}
              >
                Enquiry
              </button>
            </div>
          </div>
          {/* <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-primary">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-success">
              <i class="fa fa-whatsapp" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-warning">
              <i class="fa fa-share" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-danger">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </button>
          </div> */}
        </div>
        <div class="ps-product-list ps-clothings  ">
          <div class="ps-section__header my-5 border border-primary">
            <h3>Latest Update </h3>
          </div>
        </div>
        <div class="row p-2">
          {postList?.listing &&
            postList?.listing?.map((data, i) => {
              return (
                <div class="col-md-3 col-6 p-2">
                  <div class="card p-2 text-center">
                    <img
                      src={
                        data?.featureImage && data?.featureImage !== undefined
                          ? URL?.API_BASE_URL + data?.featureImage
                          : "/img/message/sudarshanchakra.gif"
                      }
                      class="mevenderfetureproduct"
                    />
                    <h4 class="mt-3">
                      {" "}
                      <Link to={"/listing/" + data?.pro_slug}>
                        <TruncateText
                          text={data?.title}
                          maxLength={windowWidth > 600 ? 20 : 8}
                        />
                      </Link>
                    </h4>
                    {data?.afterdiscountprice && (
                      <h5 class="text-success">
                        <span>MRP</span> <span>{data?.afterdiscountprice}</span>
                      </h5>
                    )}
                  </div>
                </div>
              );
            })}
          {/* <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div> */}
        </div>

        <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>why choose us</h3>
          </div>
        </div>
        <div class="row p-2">
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
        </div>
        <RecentProduct postList={postList} />
        {/* <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>feature products</h3>
          </div>
        </div>
        <div class="row p-2">
          {postList?.product &&
            postList?.product?.map((data, i) => {
              return (
                <div class="col-md-3 col-6 p-2">
                  <div
                    class="card p-2 text-center
              "
                  >
                    <Link to={"/product/" + data?.pro_slug}>
                      <img
                        src={
                          data?.featureImage && data?.featureImage !== undefined
                            ? URL?.API_BASE_URL + data?.featureImage
                            : "/img/message/sudarshanchakra.gif"
                        }
                        class="mevenderfetureproduct"
                      />
                    </Link>

                    <h4 class="mt-3">
                      <Link to={"/product/" + data?.pro_slug}>
                        <TruncateText
                          text={data?.title}
                          maxLength={windowWidth > 600 ? 20 : 8}
                        />
                      </Link>{" "}
                    </h4>
                    {data?.afterdiscountprice && (
                      <h5 class="text-success">
                        MRP <span>{data?.afterdiscountprice}</span>
                      </h5>
                    )}
                  </div>
                </div>
              );
            })}
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/subsubcreateCategory/UeFWemFMI-634f4d599a912e7d15570f52-likangyue-ladies-sports-running-walking.jpg"
                class="mevenderfetureproduct"
              />
              <h4 class="mt-3">Product Name</h4>
              <h5 class="text-success">
                MRP <span>999</span>
              </h5>
            </div>
          </div>
           
        </div> */}

        <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>About-us</h3>
          </div>
        </div>
        <div class="card">
          <div class="row p-10">
            <div class="col-md-4 col-6">
              <img
                src="https://api.bestbharat.com/static/subsubcreateCategory/tp56uXy7P-images_(1).jpg"
                class="aboutusimgevender"
              />
            </div>
            <div class="col-md-8 col-6">
              <ul>
                <li>Focus on your buyer persona</li>
                <li>Provide testimonials, case study data</li>
                <li>Show personality.</li>
                <li>Make your page </li>
                <li>Provide testimonials, case study data</li>
                <li>Show personality.</li>
                <li>Make your page </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>News / Blogs slider</h3>
          </div>
        </div>
        <div class="row p-2">
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg"
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg"
                class="mevenderfetureproduct"
              />
            </div>
          </div>
        </div>

        <h3 class="text-center mt-3">Slider For Vendor</h3>

        <h3 class="text-center mt-3 bg-primary text-white">
          <b>My Team Slider</b>
        </h3>
        {/* <div class="row p-2">
        <div class="col-md-3 col-6 p-2">
          <div class="card text-center">
            <img src="https://5.imimg.com/data5/QP/HX/GLADMIN-50767027/private-client-and-family-advisory-services-500x500.png" class="teammemberimage mx-auto d-block" />
            <div class="card-body">
              <h4 class="mt-4"><span class="text-primary"><b>Ankit Roy </b></span></h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6 p-2">
          <div class="card text-center">
            <img src="https://citygirlbusinessclub.com/wp-content/uploads/2015/12/bigstock-Young-happy-businesswoman-in-o-26890943-12.jpg" class="teammemberimage mx-auto d-block" />
            <div class="card-body">
              <h4 class="mt-4"><span class="text-primary"><b>Isika Dubee </b></span></h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6 p-2">
          <div class="card text-center">
            <img src="https://5.imimg.com/data5/QP/HX/GLADMIN-50767027/private-client-and-family-advisory-services-500x500.png" class="teammemberimage mx-auto d-block" />
            <div class="card-body">
              <h4 class="mt-4"><span class="text-primary"><b>Ankit Roy </b></span></h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6 p-2">
          <div class="card text-center">
            <img src="https://citygirlbusinessclub.com/wp-content/uploads/2015/12/bigstock-Young-happy-businesswoman-in-o-26890943-12.jpg" class="teammemberimage mx-auto d-block" />
            <div class="card-body">
              <h4 class="mt-4"><span class="text-primary"><b>Isika Dubee </b></span></h4>
            </div>
          </div>
        </div>
      </div> */}
        <div class="row m-0 p-0">
          <div class="col-md-3 col-6 p-2 m-0">
            <div class="team-member text-center">
              <img
                src="https://5.imimg.com/data5/QP/HX/GLADMIN-50767027/private-client-and-family-advisory-services-500x500.png"
                alt="Team Member 1"
                class="teammemberimage mx-auto d-block"
              />
              <h4>John Doe</h4>
              <h6 class="role">Designer</h6>
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-linkedin"></a>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2 m-0">
            <div class="team-member text-center">
              <img
                src="https://citygirlbusinessclub.com/wp-content/uploads/2015/12/bigstock-Young-happy-businesswoman-in-o-26890943-12.jpg"
                alt="Team Member 2"
                class="teammemberimage mx-auto d-block"
              />
              <h4>miss Yogita</h4>
              <h6 class="role">Developer</h6>
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-linkedin"></a>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2 m-0">
            <div class="team-member text-center">
              <img
                src="https://5.imimg.com/data5/QP/HX/GLADMIN-50767027/private-client-and-family-advisory-services-500x500.png"
                alt="Team Member 1"
                class="teammemberimage mx-auto d-block"
              />
              <h4>John Doe</h4>
              <h6 class="role">Designer</h6>
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-linkedin"></a>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2 m-0">
            <div class="team-member text-center">
              <img
                src="https://citygirlbusinessclub.com/wp-content/uploads/2015/12/bigstock-Young-happy-businesswoman-in-o-26890943-12.jpg"
                alt="Team Member 2"
                class="teammemberimage mx-auto d-block"
              />
              <h4>miss Yogita</h4>
              <h6 class="role">Developer</h6>
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-linkedin"></a>
            </div>
          </div>
        </div>

        {/* <div className="p-25">
          <div className="row justify-content-center mt-5">
            <div className="input-box col-12 col-sm-6 p-5">
              <i className="uil uil-search"></i>
              <input type="text" placeholder="Search here..." />
              <i className="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                >
                  <path
                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                    fill="rgba(234,173,33,1)"
                  ></path>
                </svg>
              </i>
            </div>
            <div className="ps-product--detail ps-product--fullwidth col-12 col-sm-6 p-5">
              <div className="ps-product__header">
                <div className="ps-product__sharing p-0 ml-5">
                  <a className="facebook" href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a className="twitter" href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a className="google" href="#">
                    <i className="fa fa-google-plus"></i>
                  </a>
                  <a className="linkedin" href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div id="homepage-1 ">
          <div id="homepage-1" className="px-4">
            <div className="ps-home-banner ps-home-banner--1">
              <div className="ps-container">
                <div className="ps-section__left">
                  <div className="card border border-primary">
                    <div className="row no-gutters">
                      <div className="col-md-6 p-25 pr-0">
                        <img
                          src={
                            vendorDetails?.sellerProfile
                              ? URL.API_BASE_URL + vendorDetails?.sellerProfile
                              : "/img/message/sudarshanchakra.gif"
                          }
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="col-md-6 p-25 pl-0">
                        <div className="ps-block__header">
                          <h4>
                            {" "}
                            {vendorDetails?.displayName
                              ? vendorDetails?.displayName
                              : vendorDetails?.fullName}
                          </h4>
                        </div>
                        <div className="ps-block__footer pb-3">
                          {valuevendor || value ? (
                            valueuser?._id !== id ? (
                              get_like_list?.userFollow?.status == "active" ? (
                                <a
                                  className="ps-btn2  "
                                  onClick={() => {
                                    hendletofollow();
                                  }}
                                  href={"#"}
                                >
                                  Following
                                </a>
                              ) : (
                                <a
                                  className="ps-btn2  "
                                  onClick={() => {
                                    hendletofollow();
                                  }}
                                  href={"#"}
                                >
                                  Follow
                                </a>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {valuevendor || value ? (
                            valueuser?._id !== id ? (
                              get_like_list?.userlike?.status == "active" ? (
                                <a
                                  className="ps-btn2 ml-10 "
                                  onClick={() => {
                                    hendletoLike();
                                  }}
                                  href={"#"}
                                >
                                  <i className="fa fa-thumbs-up"></i>
                                </a>
                              ) : (
                                <a
                                  className="ps-btn2  ml-10"
                                  onClick={() => {
                                    hendletoLike();
                                  }}
                                  href={"#"}
                                >
                                  <i className="fa fa-thumbs-o-up"></i>
                                </a>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <span className="ps-block__divider"></span>
                        <div className="ps-block__content">
                          <p>
                            <strong>Address</strong> {vendorDetails?.city},{" "}
                            {vendorDetails?.state}, {vendorDetails?.country},
                            {vendorDetails?.zip}
                          </p>
                        </div>
                        <div className="ps-block__footer pb-3">
                          <p>
                            Call us directly
                            <strong> {vendorDetails?.phone}</strong>
                          </p>
                          <p>or Or if you have any question</p>

                          <a
                            className="ps-btn ps-btn--fullwidth"
                            href={"tel:" + vendorDetails?.phone}
                          >
                            Contact Seller
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ps-section__right">
                  <a className="ps-collection" href="#">
                    <img src="/img/slider/home-1/promotion-1.jpg" alt="" />
                  </a>
                  <a className="ps-collection" href="#">
                    <img src="/img/slider/home-1/promotion-2.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <LatestListing postList={postList} />

        <div class="ps-product-list ps-clothings mt-5 px-3">
          <div class="ps-section__header my-5 border border-primary">
            <h3>News / Blogs</h3>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12  px-25">
              <div class="testimonial-item border p-15 bg-light shadow-lg">
                <div class="d-flex align-items-center">
                  <div class="">
                    <img src="/img/img1111.jpg" alt="client_image" />
                  </div>
                  <div class="mx-4">
                    <h4 class="text-dark">Client Name</h4>
                  </div>
                </div>
                <div class="border-top text-secondary text-justify mt-4 pt-3">
                  <p class="mb-0">
                    Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                    interdum aliquam dolor eget urna. Nam volutpat libero sit
                    amet leo cursus, ac viverra eros morbi quis quam mi.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12  px-25">
              <div class="testimonial-item border p-15 bg-light shadow-lg">
                <div class="d-flex align-items-center">
                  <div class="">
                    <img src="/img/img2222.jpg" alt="client_image" />
                  </div>
                  <div class="mx-4">
                    <h4 class="text-dark">Client Name</h4>
                  </div>
                </div>
                <div class="border-top mt-4 pt-3">
                  <p class="mb-0 text-secondary text-justify">
                    Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                    interdum aliquam dolor eget urna. Nam volutpat libero sit
                    amet leo cursus, ac viverra eros morbi quis quam mi.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12  px-25">
              <div class="testimonial-item border p-15  bg-light shadow-lg">
                <div class="d-flex align-items-center">
                  <div class="">
                    <img src="/img/img3333.jpg" alt="client_image" />
                  </div>
                  <div class="mx-4">
                    <h4 class="text-dark">Client Name</h4>
                  </div>
                </div>
                <div class="border-top text-secondary text-justify mt-4 pt-3">
                  <p class="mb-0">
                    Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                    interdum aliquam dolor eget urna. Nam volutpat libero sit
                    amet leo cursus, ac viverra eros morbi quis quam mi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorStoreCom;
