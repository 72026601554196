import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cktest from "../../cktetst/Cktest";
import { toast } from "react-toastify";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { DeleteOutlined } from "@ant-design/icons";
import HeadingBack from "../../HeadingBack";
import { URL } from "../../../Redux/common/url";
import { useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";
import Api from "../../../Redux/common/api";

function UpdateProductCom() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [aboutPage, setaboutPage] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [maincateList, setmaincateList] = useState([]);
  const [selectedOptionPr, setSelectedOptionPr] = useState(null);
  const [branList, setbrandList] = useState([]);
  const [productData, setproductData] = useState({});
  const { id } = useParams();
  const [price, setPropertyPrice] = useState(0);
  const [imagesmulti, setImagesMulti] = useState([]);

  const calculatePropertyPrice = (e) => {
    const { name, value } = e.target;

    let price = 0;
    if (name === 'size') {
      price = Number(value * productData?.peryardamount);
    } else if (name === 'peryardamount') {
      price = Number(value * productData?.size);
    }

    setPropertyPrice(price);
  }

  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.get_sub_sub_category_list
      ? state.allapi.get_sub_sub_category_list
      : []
  );

  const get_main_all_category = useSelector((state) =>
    state?.allapi?.get_main_all_category
      ? state?.allapi?.get_main_all_category
      : []
  );
  const get_sub_sub_category = useSelector((state) =>
    state?.allapi?.get_sub_sub_all_category
      ? state?.allapi?.get_sub_sub_all_category
      : []
  );
  const get_product_details = useSelector((state) =>
    state.allapi.get_product_details ? state.allapi.get_product_details : {}
  );

  const grt_all_brand = useSelector((state) =>
    state.allapi.grt_all_brand ? state.allapi.grt_all_brand : []
  );
  useEffect(() => {
    if (get_product_details?.subcolor?.length > 0) {
      const selectCate = subList.find(
        (edata) => edata?._id == get_product_details?.categoryId
      );

      if (get_product_details?.description !== undefined) {
        setaboutPage(get_product_details?.description);
      }

      setSelectedOption(selectCate);
      setproductData({ ...get_product_details, size: get_product_details?.subcolor[0]?.productsize[0]?.size });
      setPropertyPrice(get_product_details?.price);

      setImages([
        { data_url: URL.API_BASE_URL + get_product_details.featureImage },
      ]);

      const finalData = get_product_details?.subcolor?.map((data) => {
        const dataImg = data?.images?.map((data2) => {
          const finalimg = { data_url: URL.API_BASE_URL + data2.img };
          return finalimg;
        });
        data.images = dataImg;
        setImagesMulti(dataImg);
        return data;
      });

      setRows(finalData);
    }
  }, [get_product_details]);

  useEffect(() => {
    const dropList = get_main_all_category?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setmaincateList(dropList);
  }, [get_main_all_category]);
  useEffect(() => {
    if (maincateList?.length > 0 && get_product_details?.categoryDetails) {
      const getfind = maincateList.find(
        (edata) =>
          edata?._id == get_product_details?.categoryDetails[0]?._id
      );

      if (getfind !== undefined) {
        setSelectedOptionPr(getfind);
        const filterdata = get_sub_sub_category?.filter((data) => {
          return data?.parentId == getfind?._id;
        });

        const dropList = filterdata?.map((data, i) => {
          data.value = data?._id;
          data.label = data?.name;

          return data;
        });

        const getfindv = dropList.find(
          (edata) =>
            edata?._id == get_product_details?.categoryDetails[0]?.subparentId
        );

        setSelectedOption4(getfindv);
      }
    }
  }, [maincateList, get_product_details]);
  useEffect(() => {
    if (branList?.length > 0 && get_product_details?.categoryDetails) {
      const getfind = branList.find(
        (edata) => edata?._id == get_product_details?.brandId
      );
    }
  }, [branList, get_product_details]);

  useEffect(() => {
    const dropList = grt_all_brand?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setbrandList(dropList);
  }, [grt_all_brand]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    dispatch(allapiAction.getsubsubcate({}));
    dispatch(allapiAction.getbrandAllListseller({}));
    dispatch(allapiAction.productDetails(id));
    dispatch(allapiAction.getsubcateAllList({}));
    dispatch(allapiAction.getcateAllList({}));
    return () => { };
  }, [id]);

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [get_sub_sub_category_list]);

  const maxNumber = 3;
  const onChangemulti = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesMulti(imageList);
  };
  const HendleImgFVer = (e, pictureDataURLs, index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      if (!Array.isArray(updatedRows[index].images)) {
        updatedRows[index].images = [];
      }

      updatedRows[index].images.push(e[e.length - 1]);
      return updatedRows;
    });
  };
  const onRemoveImage = (variantIndex, imageIndex) => {
    setRows((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].images.splice(imageIndex, 1);
      return updatedVariants;
    });
  };

  const addSizeProduct = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].productsize.push({
        size: "",
        sizetype: "",
        price: "",
        stock: "",
      });
      return updatedRows;
    });
  };

  const removeSizeProduct = (rowIndex, sizeIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].productsize.splice(sizeIndex, 1);
      return updatedRows;
    });
  };
  const removeColorProduct = (index) => {
    setRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const handleSizeChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].productsize[sizeIndex][field] = value;
      return updatedRows;
    });
  };
  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const hendleTosubmit = async () => {
    const data = new FormData();

    if (images?.length === 0) {
      toast.error("please select feature Image");
    } else if (!productData?.title) {
      toast.error("please enter property name");
    } else if (!selectedOptionPr) {
      toast.error("please select category");
    } /* else if (!selectedOption1) {
      toast.error('please select seller')
    } */ else {
      data.append("productId", productData?._id);
      data.append("title", productData?.title);
      data.append("price", price);
      data.append("seo_title", productData?.seo_title);
      data.append("sku", productData?.sku);
      data.append("bv", productData?.bv);
      data.append("bouns", productData?.bouns);
      data.append("seo_description", productData?.seo_description);
      data.append("categoryId", selectedOption?._id);
      data.append("category1Id", selectedOptionPr?._id);
      data.append("brandId", selectedOptionPr?._id);
      data.append("category2Id", selectedOption4?._id);
      data.append("gst", productData?.gst);
      data.append("delivery_charges", productData?.delivery_charges);
      data.append("afterdiscountprice", productData?.afterdiscountprice);
      data.append("description", aboutPage);
      data.append("peryardamount", productData?.peryardamount);
      data.append("shortdiscription", productData?.shortdiscription);

      if (images[0]?.file) {
        data.append("featureImage", images[0]?.file);
      }
      data.append("categorymainId", selectedOptionPr?._id);
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      await Api.post(`${URL.updateProduct}`, data, config);

      for (const row of rows) {
        const data2 = new FormData();
        data2.append("productId", productData?._id);
        data2.append("color", row?.color);
        if (row?._id) {
          data2.append("_id", row?._id);
        }
        data2.append("gst", productData?.gst);
        data2.append("delivery_charges", productData?.delivery_charges);

        const psize = row?.productsize?.map((data) => {
          data.productcolorId = data.productcolorId
            ? data.productcolorId
            : row?._id;
          data.size = productData?.size;
          return data;
        });
        data2.append("sizeProduct", JSON.stringify(psize));
        /* imagesmulti?.forEach((file) => {
          data2.append("images", file?.file);
        }); */

        imagesmulti?.forEach((file) => {
          if (file?.file) {
            data2.append("images", file?.file);
          } else {
            const relativeUrl = file?.data_url.replace(URL.API_BASE_URL, "");
            data2.append("oldimages", relativeUrl);
          }
        });

        await Api.post(
          `${URL.updateProductvariations}`,
          data2,
          config
        );
      }

      navigate("/seller/property");
    }
  };

  return (
    <div className=" code-box">
      <div className="flex">
        <div>

          <div className="flex gap10">
            <HeadingBack
              title={"Edit Property"}
            />
          </div>
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <div>
        <div className="container pdcon">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Feature</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <ImageUploading
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Property Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  placeholder="Enter property name"
                  name="title"
                  value={productData?.title}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Scheme</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOptionPr}
                  onChange={setSelectedOptionPr}
                  options={maincateList}
                // className="mt-8"
                />
              </div>
            </div>
            <div className="col-3">
              {" "}
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Yard</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  placeholder="Enter yard"
                  name="size"
                  value={productData?.size}
                  onChange={(e) => {
                    calculatePropertyPrice(e);
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              {" "}
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Per yard amount</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  placeholder="Enter per yard amount"
                  name="peryardamount"
                  value={productData?.peryardamount}
                  onChange={(e) => {
                    calculatePropertyPrice(e);
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">price</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  className="form-control bg-transparent"
                  name="price"
                  value={price}
                // readOnly
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">bv</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter bv"
                  name="bv"
                  value={productData?.bv}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">More Image</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                {/* <div>
             
                    <ImageCropper
                      aspectRatio={4 / 3}
                      onImageCrop={handleImageCrop}
                      setfileContant={setfileContant}
                      setFileName={setFileName}
                      setfinalImg={setfinalImg}
                      aspectwidth={16}
                      aspectheight={13}
                      onCropComplete={setCroppedImageUrl}
                    />

                    {croppedImageUrl && (
                      <div>
                        <img
                          src={croppedImageUrl}
                          alt="Cropped"
                          width={100}
                          height={130}
                        />
                      </div>
                    )}
                  </div> */}
                <ImageUploading
                  multiple
                  value={imagesmulti}
                  onChange={onChangemulti}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                // acceptType={['jpg']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-success  "
                      >
                        Choose More image
                      </button>
                      &nbsp;
                      {/* <button
                          className="btn btn-secondary"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
          <div className="prosection">
            <h3>Description </h3>
            <div className="row">
              <div className="col-12">
                <div className="fv-row mb-7">
                  <label className="fs-6 fw-semibold form-label mb-2">
                    <span className="required">short description</span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-html="true"
                      data-bs-content="Permission names is required to be unique."
                    ></i>
                  </label>
                  <textarea
                    // className='form-control form-control-solid'
                    placeholder="Enter a short description"
                    name="shortdiscription"
                    value={productData?.shortdiscription}
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                    className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                </div>
              </div>
              {/* <div className="col-12">
                <div className="fv-row mb-7">
                  <label className="fs-6 fw-semibold form-label mb-2">
                    <span className="required">Description</span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-html="true"
                      data-bs-content="Permission names is required to be unique."
                    ></i>
                  </label>
                  <Cktest
                    aboutPage={aboutPage}
                    setaboutPage={setaboutPage}
                    uploadPlugin={uploadPlugin}
                  />
                </div>
              </div> */}
              {/* <div className="col-3">
                <div className="fv-row mb-7">
                  <label className="fs-6 fw-semibold form-label mb-2">
                    <span className="required">seo title</span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-html="true"
                      data-bs-content="Permission names is required to be unique."
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder="Enter a seo title"
                    name="seo_title"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                    className="form-control bg-transparent"
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="fv-row mb-7">
                  <label className="fs-6 fw-semibold form-label mb-2">
                    <span className="required">seo description</span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-html="true"
                      data-bs-content="Permission names is required to be unique."
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder="Enter a seo description"
                    name="seo_description"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                    className="form-control bg-transparent"
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              {" "}
              <button
                className="btn btn-primary btn-lg"
                onClick={() => hendleTosubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProductCom;
