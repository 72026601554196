// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../Loader";
import OurWork from "../OurWork/OurWork";
import OurWork2 from "../OurWork/OurWork2";
import Category from "./Category";
import HomeSlider from "./HomeSlider";
import Listing from "./Listing";
import Listors from "./Listors";
import NavigateBtn from "./NavigateBtn";
import Product from "./Product";
import RecentProduct from "./RecentProduct";
import Video from "./Video";
import Winner from "./Winner";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import axios from "axios";
import Seo from "../Seo/Seo";
import FeProduct from "./FeProduct";
import Branch from "../OurWork/Branch";

function HomeComponent() {
  const dispatch = useDispatch();

  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );

  const get_home_all_pro = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];

  useEffect(() => {
    dispatch(allapiAction.gethomePro({}));
    dispatch(allapiAction.getsliderList({ page: "home" }));
    dispatch(allapiAction.getMarqueesList2({ page: "home" }));
  }, []);

  const handleCreateOrder = async () => {
    try {
      const response = await axios.post(
        URL?.API_BASE_URL + "/publicApi/createOrdersq",
        {
          customerMobile: "9636996332",
          amount: "1",
          orderId: "testmrncxavfrrajm",
          redirectUrl: "http://localhost:6001",
          remark1: "test",
          remark2: "testsdt",
        }
      );

      if (response.data.status) {
        window.location.href = response.data.payment_url;
      } else {
        alert("Failed to create order");
      }
    } catch (error) {
      console.error("Error creating order:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <Seo
        settitle={"Devimart"}
        titleSeo={"Devimart"}
        seoKeyword={"Devimart"}
        seo_descriptions={"Devimart"}
        seoconcal={"https://devimart.com/"}
        title={"Devimart"}
      />
      {Object.keys(get_home_all_pro)?.length > 0 ? (
        <div className="hometest">
          <div className="marquee relative">
            {/* <img
              src="/img/JOBPORTAL.png"
              alt="BeestBharatmessage.png"
              width={29}
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                zIndex: "1",
              }}
            /> */}
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div>

          {/* <button
            onClick={() => {
              handleCreateOrder();
            }}
          >
            pay
          </button> */}
          {/* <OurWork2 homedata={get_home_all_pro} /> */}
          {/* <div className="marquee">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}

          <div className="">
            <HomeSlider />
          </div>

          {/* coment only for now */}
          {/* <div className="marquee mt-5">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {admq &&
                admq?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}
          {/* <NavigateBtn homedata={get_home_all_pro} /> */}
          {/* <Product homedata={get_home_all_pro} /> */}
          <Category homedata={get_home_all_pro} />
          {/* <Listing homedata={get_home_all_pro} /> */}
          <FeProduct homedata={get_home_all_pro} />
          <RecentProduct homedata={get_home_all_pro} />

          {/* <Winner homedata={get_home_all_pro} /> */}
          {news?.length > 0 && (
            <div className="  ">
              <div className="mark_bg mt-2">
                <div className="headertext">Latest Notification</div>
                <div className="news">
                  <marquee>
                    {news &&
                      news?.map((data, i) => {
                        return <span key={data._id}>{data?.title}</span>;
                      })}
                  </marquee>
                </div>
              </div>
            </div>
          )}

          <div className=" ">
            {/* <Video homedata={get_home_all_pro} /> */}
            {/* <Listors homedata={get_home_all_pro} /> */}

            {/* <OurWork homedata={get_home_all_pro} /> */}
            <Branch homedata={get_home_all_pro} />
          </div>
        </div>
      ) : (
        <LoaderComponent />
      )}
      <hr />
    </div>
  );
}

export default HomeComponent;
