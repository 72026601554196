export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:6001",
  // kh
  API_BASE_URL: "https://svkapi.successdream.net",

  // ip

  // API_BASE_URL: "http://192.168.1.13:6001",

  // API_BASE_URL: "https://tiny-tan-puffer-tie.cyclic.cloud",
  // API_BASE_URL_2: "https://bestbharat.com",

  // seller

  // blog
  createBlogs: "/vendorApi/createBlogs",
  getBlogListVendor: "/vendorApi/getBlogListVendor",
  deleteBlogvendor: "/vendorApi/deleteBlogvendor",
  // product
  createListingvendor: "/vendorApi/createListingvendor",
  updateListingSeller: "/vendorApi/updateListingSeller",
  getsellerListings: "/vendorApi/getsellerListings",
  searchListing: "/vendorApi/searchListing",
  futuredProduct: "/vendorApi/futuredProduct",
  futuredListing: "/vendorApi/futuredListing",
  // futuredProduct: "/vendorApi/futuredProduct",
  getProductList: "/vendorApi/getProductList",
  searchProduct: "/vendorApi/searchProduct",
  statusProduct: "/vendorApi/statusProduct",
  deleteProduct: "/vendorApi/deleteProduct",
  // productDetails: "/vendorApi/productDetails",
  ListingDetails: "/vendorApi/ListingDetails",
  getPackageList: "/vendorApi/getPackageList",
  createPurchase: "/vendorApi/createPurchase",
  getsubsubcateseller: "/vendorApi/getsubsubcate",
  getcateAllListseller: "/vendorApi/getcateAllList",
  getbrandAllListseller: "/vendorApi/getbrandAllList",
  getsubcateAllListseller: "/vendorApi/getsubcateAllList",
  kycUpdate: "/vendorApi/kycUpdate",
  getVendorJobs: "/vendorApi/getJobs",
  createProduct2: "/vendorApi/createProduct2",
  createProductvariations: "/vendorApi/createProductvariations",
  updateProductvariations: "/vendorApi/updateProductvariations",
  updateProduct: "/vendorApi/updateProduct",
  lostfoundsubmitseller: "/vendorApi/lostfoundsubmit",
  messagesubmitSeller: "/vendorApi/messagesubmit",
  getExpireDate: "/vendorApi/getExpireDate",
  Certificatescreateseller: "/vendorApi/Certificatescreate",
  addItemCartVender: "/vendorApi/addItemCart",
  getcartitmeVender: "/vendorApi/getcartitme",
  getprofilevendor: "/vendorApi/getprofilevendor",
  updateBussLogovendor: "/vendorApi/updateBussLogo",
  updateBussLogo22header: "/vendorApi/updateBussLogo22header",
  updateBussProfileByvendor: "/vendorApi/updateBussProfileByvendor",
  Withdrawalsrequset: "/vendorApi/Withdrawalsrequset",
  vendorPaybleAmountLsitforuser: "/vendorApi/vendorPaybleAmountLsitforuser",
  getWithdrawalListverndor: "/vendorApi/getWithdrawalListverndor",
  vendorpaybleamdall: "/vendorApi/vendorpaybleamdall",
  addJob: "/vendorApi/addJob",
  editJob: "/vendorApi/editJob",
  afterLoginAddCartVender: "/vendorApi/afterLoginAddCart",
  removeItemCartCountVender: "/vendorApi/removeItemCartCount",
  addItemCartCountVender: "/vendorApi/addItemCartCount",
  removeCartItemVender: "/vendorApi/removeCartItem",
  removeAllCartItemVender: "/vendorApi/removeAllCartItem",
  getUserAddressVender: "/vendorApi/getUserAddress",
  createAddressVender: "/vendorApi/createAddress",
  userlikegetVender: "/vendorApi/userlikeget",
  userlikenowVender: "/vendorApi/userlikenow",
  userfollownowVender: "/vendorApi/userfollownow",
  createOrderVender: "/vendorApi/createOrder",
  getprofilerVender: "/vendorApi/getprofile",
  updateVenderProfile: "/vendorApi/updateprofile",
  getuserOrderVender: "/vendorApi/getuserOrder",
  getuserlostandfoundnewVender: "/vendorApi/getuserlostandfoundnew",
  deletelostandfoundVender: "/vendorApi/deletelostandfound",
  getusercertifitedeleteVender: "/vendorApi/getusercertifitedelete",
  getusercertifiteListVender: "/vendorApi/getusercertifiteList",
  getuserOrderDetailsVender: "/vendorApi/getuserOrderDetailsVendor",
  vendordeshBoradData: "/vendorApi/vendordeshBoradData",
  getuserOrdervendor: "/vendorApi/getuserOrdervendor",
  getSearchOrdervendor: "/vendorApi/getSearchOrdervendor",
  getuserreturnOrdervendor: "/vendorApi/getuserreturnOrdervendor",
  getSearchReturnOrdervendor: "/vendorApi/getSearchReturnOrdervendor",

  userallteamlistvendor: "/vendorApi/userallteamlist",
  getJobsapplyedListVendor: "/vendorApi/getJobsapplyedListVendor",

  getJobsapplyedList: "/sellerApi/getJobsapplyedList",
  getuserResume: "/sellerApi/getuserResume",
  createResume: "/sellerApi/createResume",
  jobapplyNow: "/sellerApi/jobapplyNow",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  VemdorgetSellerTicketList: "/vendorApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  VemdorcreateSellerTickets: "/vendorApi/createSellerTickets",
  createMessage: "/sellerApi/createMessage",
  VemdorcreateMessage: "/vendorApi/createMessage",
  createMarrigeProfilevender: "/vendorApi/createMarrigeProfile",
  // apipublic

  getOneUserPerCountry: "/publicApi/getOneUserPerCountry",
  getOneUserPerCity: "/publicApi/getOneUserPerCity",
  createcategories: "/apihome/createcategories",
  getCategory: "/publicApi/getCategory",
  userSearchProduct: "/publicApi/userSearchProduct",
  userSearchProductuser: "/publicApi/userSearchProductuser",
  userSearchListings: "/publicApi/userSearchListings",
  userSearchListingsUser: "/publicApi/userSearchListingsUser",
  getcartProduct: "/publicApi/getcartProduct",
  referCheck: "/publicApi/referCheck",
  getfiltercategory: "/publicApi/getfiltercategory",
  getfiltermaincategory: "/publicApi/getfiltermaincategory",
  getsubcategryListbymainCategory: "/publicApi/getsubcategryListbymainCategory",
  getsubcategryListbymsubCategory: "/publicApi/getsubcategryListbymsubCategory",
  getsellerProductListbysubcategoryNew:
    "/publicApi/getsellerProductListbysubcategoryNew",
  studentVideoPubAll: "/publicApi/studentVideoPubAll",
  getjobDetailst: "/publicApi/getjobDetailst",
  getjobreleted: "/publicApi/getjobreleted",
  MerrageList: "/publicApi/MerrageList",

  registerUser: "/publicApi/registerUser",
  otpVerifyuser: "/publicApi/otpVerifyuser",
  getsellersList: "/publicApi/getsellersList",
  lostfoundlistpublic: "/publicApi/lostfoundlistpublic",
  lostfoundlistsearchpublic: "/publicApi/lostfoundlistsearchpublic",
  //  message
  messagelistpublic: "/publicApi/messagelistpublic",
  MerriageList: "/publicApi/MerriageList",
  Marriagedetaials: "/publicApi/Marriagedetaials",
  getrectentBlog: "/publicApi/getrectentBlog",
  getblogcateAllList: "/publicApi/getblogcateAllList",
  // user

  // job portal
  getjoballpageList: "/publicApi/getjoballpageList",
  getjobcategoryWiseList: "/publicApi/getjobcategoryWiseList",
  getjobCategoryallpageList: "/publicApi/getjobCategoryallpageList",

  addItemCart: "/sellerApi/addItemCart",
  getcartitme: "/sellerApi/getcartitme",
  afterLoginAddCart: "/sellerApi/afterLoginAddCart",
  removeItemCartCount: "/sellerApi/removeItemCartCount",
  addItemCartCount: "/sellerApi/addItemCartCount",
  removeCartItem: "/sellerApi/removeCartItem",
  removeAllCartItem: "/sellerApi/removeAllCartItem",
  createAddress: "/sellerApi/createAddress",
  userlikeget: "/sellerApi/userlikeget",
  userlikenow: "/sellerApi/userlikenow",
  userfollownow: "/sellerApi/userfollownow",
  createOrder: "/sellerApi/createOrder",
  getUserAddress: "/sellerApi/getUserAddress",
  getuserOrder: "/sellerApi/getuserOrder",
  getuserlostandfoundnew: "/sellerApi/getuserlostandfoundnew",
  deletelostandfound: "/sellerApi/deletelostandfound",
  getusercertifitedelete: "/sellerApi/getusercertifitedelete",
  getusercertifiteList: "/sellerApi/getusercertifiteList",
  getuserOrderDetails: "/sellerApi/getuserOrderDetails",

  getuserdeshboardData: "/sellerApi/getuserdeshboardData",

  getourwork: "/publicApi/getourwork",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcatehome: "/publicApi/getsubsubcatehome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  productDetailsReleted: "/publicApi/productDetailsReleted",
  productDetailsReletedBrand: "/publicApi/productDetailsReletedBrand",
  productShopDetails: "/publicApi/productShopDetails",
  ListingShopDetails: "/publicApi/ListingShopDetails",
  ListingstDetails: "/publicApi/ListingstDetails",
  ListingsDetailsReleted: "/publicApi/ListingsDetailsReleted",

  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerProductListbycategoryNew:
    "/publicApi/getsellerProductListbycategoryNew",
  getsellerprofile: "/publicApi/getvendorprofile",
  getvendorprofileproduct: "/publicApi/getvendorprofileproduct",
  lostfoundsdetails: "/publicApi/lostfoundsdetails",
  CertificatesDetails: "/publicApi/CertificatesDetails",
  messageDetails: "/publicApi/messageDetails",
  lostfoundlist: "/publicApi/lostfoundlist",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  gethomePro: "/publicApi/homepgedata",
  studentVideoPub: "/publicApi/studentVideoPub",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  geteducatetDetails: "/publicApi/geteducatetDetails",
  getsellersListFilter: "/publicApi/getsellersListFilter",
  geteducatetDetailsList: "/publicApi/geteducatetDetailsList",
  geteducatetttcate: "/publicApi/geteducatetttcate",
  geteducatListpub: "/publicApi/geteducatListpub",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  getBussProfile1: "/sellerApi/getBussProfile1",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",

  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  updateUserProfile: "/sellerApi/updateprofile",
  genelogybyuser: "/sellerApi/genelogybyuser",
  genelogybyuserTeam: "/sellerApi/genelogybyuserTeam",
  getuserBussnissMonth: "/sellerApi/getuserBussnissMonth",
  updatesellerProduct: "/sellerApi/updatesellerProduct",

  getmessageSupportList: "/sellerApi/getmessageSupportList",

  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/publicApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",

  getsellerprofilebyseller: "/sellerApi/getsellerprofilebyseller",
  sellerDateWiseReport: "/sellerApi/sellerDateWiseReport",

  checkstocknow: "/sellerApi/checkstocknow",
  userMonthIncome: "/sellerApi/userMonthIncome",
  userstatement: "/sellerApi/userstatement",
  createChelenge: "/sellerApi/createChelenge",
  userallteamlist: "/sellerApi/userallteamlist",
  userallteamlistfilter: "/sellerApi/userallteamlistfilter",
  weekWiseSale: "/sellerApi/weekWiseSale",
  dayWiseSale: "/sellerApi/dayWiseSale",
  myTeamCount: "/sellerApi/myTeamCount",
  getChelenge: "/sellerApi/getChelenge",
  edituserProfileByuser: "/sellerApi/edituserProfileByuser",
  edituserKycByUser: "/sellerApi/edituserKycByUser",
  lostfoundsubmit: "/sellerApi/lostfoundsubmit",
  messagesubmit: "/sellerApi/messagesubmit",
  createMarrigeProfile: "/sellerApi/createMarrigeProfile",
  Certificatescreate: "/sellerApi/Certificatescreate",
  applyforrefferalProgram: "/sellerApi/applyforrefferalProgram",

  getsliderList: "/publicApi/getsliderList2",
  getMarqueesList2: "/publicApi/getMarqueesList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
  createContactqueries: "/publicApi/createContactqueries",

  // level
  sellerDirectTeam: "/sellerApi/sellerDirectTeam",
  sellerDirectTeamvendor: "/vendorApi/sellerDirectTeam",
  getlavelincomeUser: "/sellerApi/getlavelincomeUser",
  getlavelincomeUservendor: "/vendorApi/getlavelincomeUser",
  getDireactncomeUser: "/sellerApi/getDireactncomeUser",
  getDireactncomeUservendor: "/vendorApi/getDireactncomeUser",
  getBonusIncomeUser: "/sellerApi/getBonusIncomeUser",
  getBonusIncomeUservendor: "/vendorApi/getBonusIncomeUser",
  getMatchingIncomeUser: "/sellerApi/getMatchingIncomeUser",
  getMatchingIncomeUservendor: "/vendorApi/getMatchingIncomeUser",
  getRelavelincomeUser: "/sellerApi/getRelavelincomeUser",
  getRelavelincomeUservendor: "/vendorApi/getRelavelincomeUser",
  getReMatchingIncomeUser: "/sellerApi/getReMatchingIncomeUser",
  getReMatchingIncomeUservendor: "/vendorApi/getReMatchingIncomeUser",
  getReDireactncomeUser: "/sellerApi/getReDireactncomeUser",
  getReDireactncomeUservendor: "/vendorApi/getReDireactncomeUser",
};
