// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";

function RecentProduct({ postList }) {
  const get_home_all_pro = postList;

  const recentProduct = get_home_all_pro?.fetured
    ? get_home_all_pro?.fetured
    : [];
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.fetured?.length == 1
        ? 1
        : get_home_all_pro?.fetured?.length > 6
          ? 6
          : get_home_all_pro?.fetured?.length - 1,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.fetured?.length == 1
              ? 1
              : get_home_all_pro?.fetured?.length > 4
                ? 4
                : get_home_all_pro?.fetured?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.fetured?.length == 1
              ? 1
              : get_home_all_pro?.fetured?.length > 3
                ? 3
                : get_home_all_pro?.fetured?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.fetured?.length == 1
              ? 1
              : get_home_all_pro?.fetured?.length > 2
                ? 2
                : get_home_all_pro?.fetured?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div className="ps-deal-of-day shewBox">
        <div className="ps-container">
          <div className="ps-section__header">
            <div className="ps-block--countdown-deal">
              <div className="ps-block__left">
                <b>
                  {" "}
                  <h3>feature Product</h3>
                </b>
              </div>
            </div>
            {/* <Link to="/products">View all</Link> */}
          </div>
          <div className="ps-section__content mt-10">
            {recentProduct?.length > 0 && (
              <Slider {...settings}>
                {recentProduct &&
                  recentProduct?.map((data, i) => {
                    return (
                      <div className="recentlostfound" key={i}>
                        <div className="ps-product">
                          <div className="ps-product__thumbnail">
                            <Link
                              to={
                                data?.typeof == "Listing"
                                  ? "/listing/" + data?.pro_slug
                                  : "/product/" + data?.pro_slug
                              }
                            >
                              <CoustomImg
                                url={URL.API_BASE_URL + data?.featureImage}
                                altkey={data?.title}
                                w1={200}
                                w2={350}
                                h1={260}
                                h2={320}
                              />
                            </Link>
                          </div>
                          <div className="ps-product__container">
                            <div className="ps-product__content">
                              <Link
                                className="ps-product__title"
                                to={
                                  data?.typeof == "Listing"
                                    ? "/listing/" + data?.pro_slug
                                    : "/product/" + data?.pro_slug
                                }
                              >
                                <TruncateText
                                  text={data?.title}
                                  maxLength={windowWidth > 600 ? 25 : 15}
                                />
                              </Link>

                              {data?.afterdiscountprice && data?.price && (
                                <p className="ps-product__price sale">
                                  ₹{data?.afterdiscountprice}{" "}
                                  <del>₹{data?.price} </del>
                                </p>
                              )}
                              {/* <Link
                                className="ps-product__vendor"
                                to={"/vendor-store/" + data?.sellerId?._id}
                              >
                                {data?.sellerId?.displayName}
                              </Link> */}

                              {/* <p>
                                <span>
                                  <i>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="18"
                                      height="18"
                                    >
                                      <path
                                        d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
                                        fill="rgba(240,187,64,1)"
                                      ></path>
                                    </svg>
                                  </i>
                                </span>
                                {data?.sellerId?.district},
                                {data?.sellerId?.state}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentProduct;
